import React, { useState, useEffect } from "react";

import './EditBtn.css';

import { SvgClose } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { Reg_EditBtn } from "services/RegisterData";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

export default function PopUP_EditBtn(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ name, setName ] = useState('');

    function SaveData(){
        props.setLoading(true);
        Reg_EditBtn(name, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('EditBtn', false);
    }

    useEffect(()=>{
        RegisterModalData('EditBtn', setModaldata);
        RegisterModalObserver('EditBtn', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setName(GetDataPage('config').name_btn);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_EditBtn">
                        <div className="div_data type_div">
                            <div className="title">
                                Editar nome do botão
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="space_width" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="40" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                </div>

                                <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                    Salvar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
