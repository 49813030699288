import { useState, useEffect } from "react";

import './About.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_AltPosition } from "services/RegisterData";

export default function Page_About(props){

    const [ search, setSearch ] = useState('');    
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('page_site'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(InitialData());

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(showPageData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    function InitialData(){
        const newOrigin = GetDataPage('page_site').filter(item => item.origin_ == 'about');
        const newData   = newOrigin.filter(item => item.idioma == idioma);
        return newData;
    }
    
    function SearchInput(value){
        const newOrigin = GetDataPage('page_site').filter(item => item.origin_ == 'about');
        const newData   = newOrigin.filter(item => item.idioma == idioma);
        const newList   = [];
        if(value){
            newData.forEach(item =>{
                if(item.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(newData);
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "page_about", "type" : "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'page_about_details')
        SetListPag('currentPageId', idPage);
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('page_about', id, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{        
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPage', setCurrentPage);
        RegisterDataPage('page_site', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterDataPage('page_site', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setShowPageData(InitialData());
    }, [currentPage, dataPage, idioma]);

    return(
        <div className="Page_About">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Quem somos</div>
                <div className="list_opt_alt_page">
                    <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                        <div className="new_data_add">
                            <SvgAddNewData color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">bloco</div>
                    </div>
                </div>
            </div>

            <div className="type_data_fixed">
                <div className="" />
                <div className="notification">
                    *Os dados serão mostrados no site, na mesma ordem que estão listados abaixo
                </div>
                
                <div className="type_idioma">
                    <div className={ idioma == "pt_br" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'pt_br') } }>pt_br</div>
                    <div className={ idioma == "pt_pt" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'pt_pt') } }>pt_pt</div>
                    <div className={ idioma == "es" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'es') } }>es</div>
                    <div className={ idioma == "en" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'en') } }>en</div>
                </div> 
               
            </div>
            
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="200">Tipo de bloco</th>
                            <th className="">Título</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.type }</td>
                                        <td>{ elem.title_page }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                {
                                                    index == 0 ?
                                                    <div className="space_div_icon" /> :
                                                    <div data-tooltip-id={ "position_" + elem.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(elem.id, elem.order_, 'up') } }>
                                                        <SvgSetaUp color="#324d6b" className="icons"/>
                                                    </div>
                                                }                                                
                                                {
                                                    (index + 1) == currentItens.length ?
                                                    <div className="space_div_icon" /> :
                                                    <div data-tooltip-id={ "position_" + elem.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(elem.id, elem.order_, 'down') } }>
                                                        <SvgSetaDown color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                <Tooltip id={ "position_" + elem.id } />

                                                <div data-tooltip-id={ "user_delete_" + elem.id } data-tooltip-content="Deletar bloco" data-tooltip-place="top" onClick={ ()=>{ DeleteData(elem.id, elem.title_page) } } title="Deletar bloco">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "user_delete_" + elem.id } />
                                                <div data-tooltip-id={ "user_edit_" + elem.id } data-tooltip-content="Editar bloco" data-tooltip-place="top" onClick={ ()=>{ PageClick(elem.id) } } title="Editar bloco">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "user_edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}