import { useState, useEffect } from "react";

import './Purchase.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgEdit } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";

export default function Page_Purchase(props){

    const [ search, setSearch ] = useState('all');
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('purchase'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('purchase'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(showPageData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value == "all"){
            setShowPageData(GetDataPage('purchase'));
        }else {
            GetDataPage('purchase').forEach(item =>{
                if(item.status == value){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);

        }
        setSearch(value);
    }

    function OpenPurchase(id){
        SetModalData('Purchase', id);
        SetModalState('Purchase', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('currentPage', setCurrentPage);
        RegisterDataPage('purchase', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterDataPage('purchase', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setShowPageData(GetDataPage('purchase'));
    }, [dataPage]);

    return(
        <div className="Page_Purchase">
            <div className="new_block">
                <div className="input_search">
                    <label style={ { whiteSpace: "nowrap" } }>Status da compra</label>
                    <select onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search }>
                        <option value={ "all" }>Todos</option>
                        <option value={ 0 }>Pendente</option>
                        <option value={ 1 }>Finalizado</option>
                        <option value={ 2 }>Cancelado</option>
                    </select>
                </div>
                <div className="title_page">Compras no site</div>
            </div>
            
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="80">E-mail</th>
                            <th width="80">Contato</th>
                            <th width="80">Data</th>
                            <th width="60">Valor</th>
                            <th width="60">Status</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                let showDataUser = '';
                                if(GetDataPage("access_site").find(item => item.id == elem.id_user)){
                                    showDataUser = GetDataPage("access_site").find(item => item.id == elem.id_user);
                                }

                                let statusPurchase = 'Pendente';
                                if(elem.status == 1){
                                    statusPurchase = 'Finalizado';
                                }else if(elem.status == 2){
                                    statusPurchase = 'Cancelado';
                                }

                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        {
                                            showDataUser.name !=undefined ?
                                            <>
                                                <td>{ showDataUser.name }</td>
                                                <td>{ showDataUser.email }</td>
                                                <td>{ showDataUser.contact }</td>
                                            </>
                                            :
                                            <td colSpan={ 3 } align="center">Usuário deletado</td>
                                        }
                                        <td align="center">{ elem.date }</td>
                                        <td>{ elem.cash_total }</td>
                                        <td>{ statusPurchase }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div data-tooltip-id={ "edit_" + elem.id } data-tooltip-content="Informações sobre a compra" data-tooltip-place="top" onClick={ ()=>{ OpenPurchase(elem.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td colSpan={ 8 } align="center">Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}