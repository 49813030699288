import { useState, useEffect } from "react";

import './Emails.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgPdf, SvgSave } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Emails } from "services/RegisterData";

export default function Page_Emails(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("emails"));
    const [ listEmails, setListEmails ] = useState(dataPage);

    function HandleData(type, index, value){
        const newData = [...listEmails];
        if(type == 'file'){            
            if(value){
                newData[index]['file']   = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file']   = '';
                newData[index]['status'] = false;
            }
        }
        newData[index][type] = value;
        setListEmails(newData);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Emails(userId, idioma, listEmails, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('emails', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterDataPage('emails', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
        setDataPage(GetDataPage("emails"));

        setListEmails(dataPage);
    }, [currentPage, dataPage, idioma]);
    
    return(
        <div className="Page_Emails">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="title_page">Texto dos e-mails</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div> 

                {
                    listEmails.map((elem, index)=>{
                        return(               
                            <div className="show_page_data" key={ index }>
                                <div className="list_type_data">
                                    <div className="type_title">{ elem.title }</div>
                                </div> 
                                {
                                    elem.id == 1 ? null :   
                                    <div className="list_input_data">
                                        <div className="text_inf">
                                            Arquivo que será enviado em anexo
                                        </div>
                                        <span className="div_add_img">
                                            <div className={ elem.status ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { elem.status ? "Arquivo adicionado" : "Adicionar arquivo" }
                                                    <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="application/pdf" />
                                                </label>
                                            </div>
                                            {
                                                elem.file !='' ?
                                                <>
                                                    <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                        <SvgDelete className="icons" color="#ffffff" />
                                                    </div>
                                                    {
                                                        elem.status ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(elem.file)) } }>
                                                            <SvgPdf color="#2D3C53" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', elem.file) } }>
                                                            <SvgPdf color="#2D3C53" className="icons"/>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </span>
                                    </div>
                                }
                                <div className="list_input_data">   
                                    <div className="div_input space_div">
                                        <JoditEditor config={ config } value={ elem.text ? elem.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData('text', index, newContent) } />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </form>
        </div>
    )
}