
let DataPage = {
    "access_dash" : [],
    "access_site" : [],
    "questions" : [],
    "page_site" : [],
    "questionnaire" : [],
    "health" : [],

    "products" : [],
    "products_category" : [],
    "products_text" : [],

    "purchase" : [],

    "config" : {},
    "emails" : []
};

let NotifyDataPage = {
    "access_dash" : [],
    "access_site" : [],
    "questions" : [],
    "page_site" : [],
    "questionnaire" : [],
    "health" : [],

    "products" : [],
    "products_category" : [],
    "products_text" : [],

    "purchase" : [],

    "config" : [],
    "emails" : []
}

export function SetListData(value) {
    DataPage = value;        
    NotifyDataPage["access_dash"].forEach(element => { element(value["access_dash"]); });
    NotifyDataPage["access_site"].forEach(element => { element(value["access_site"]); });
    NotifyDataPage["questions"].forEach(element => { element(value["questions"]); });
    NotifyDataPage["page_site"].forEach(element => { element(value["page_site"]); });
    NotifyDataPage["questionnaire"].forEach(element => { element(value["questionnaire"]); });
    NotifyDataPage["health"].forEach(element => { element(value["health"]); });
    NotifyDataPage["products"].forEach(element => { element(value["products"]); });
    NotifyDataPage["products_category"].forEach(element => { element(value["products_category"]); });
    NotifyDataPage["products_text"].forEach(element => { element(value["products_text"]); });
    NotifyDataPage["purchase"].forEach(element => { element(value["purchase"]); });
    NotifyDataPage["config"].forEach(element => { element(value["config"]); });
    NotifyDataPage["emails"].forEach(element => { element(value["emails"]); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}
