import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import Login from "templates/Login";
import Recover from "templates/Recover";
import Contents from "templates/Contents";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePageId, typeCookieIdioma } from "fixedData";

import { Access } from "services/AccessLogin";

import PopUP_ShowFile from 'components/PopUp/ShowFile';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
import PopUP_GeneratorLink from "components/PopUp/GeneratorLink";
import PopUP_EditCategory from "components/PopUp/EditCategory";
import PopUP_Purchase from "components/PopUp/Purchase";

export default function App() {

    const cookies = new Cookies();
    const [ loading, setLoading ] = useState(false);
    const [ typePage, setTypePage ] = useState(GetListPag('page'));

    let url = new URLSearchParams(window.location.search);
    let infUrl = url.get("recover");

    function OpenPage(type, value){
        if(value !='recover'){
            window.history.pushState("", "", "/");
        }
        SetListPag(type, value);
    }

    function CallbackError(){
    }

    function CheckedPage(){
        switch(typePage){
            case "recover":
                let sepLink = infUrl.split('/');
                if(sepLink[0] == 'recover'){
                    return <Recover setLoading={ setLoading } code={ sepLink[1] } />;
                }else {
                    return <Login setLoading={ setLoading } />;
                }
                
            case "dashboard":
                return <Contents setLoading={ setLoading } />;

            default:
                return <Login setLoading={ setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
        if(cookies.get(typeCookie)){
            let email = cookies.get(typeCookieEmail);
            let passw = cookies.get(typeCookiePassw);
            Access('return', email, passw, ()=>{ }, CallbackError);
        }
        
        if(infUrl){
            OpenPage('page', 'recover');
        }

        if(cookies.get(typeCookieIdioma)){
            let idioma = cookies.get(typeCookieIdioma);
            if(idioma == 'undefined'){
                SetListPag('idioma', 'pt_pt');
            }else {
                SetListPag('idioma', idioma);
            }
        }

        if(cookies.get(typeCookiePageId)){
            let idPage = cookies.get(typeCookiePageId);
            if(idPage == 'undefined'){
                SetListPag('currentPage', 'index');
                SetListPag('currentPageId', 'remuve');
            }else {
                SetListPag('currentPageId', idPage);
            }
        }
    }, []);

    return (
        <>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                </div>
            </div>
            { CheckedPage() }
            <PopUP_EditCategory setLoading={ setLoading } />
            <PopUP_EditProfile setLoading={ setLoading } />
            <PopUP_Purchase setLoading={ setLoading } />
            <PopUP_Confirmation setLoading={ setLoading } />
            <PopUP_GeneratorLink setLoading={ setLoading } />

            <PopUP_ShowFile />
            <PopUP_ReturnResponse />
        </>
    );
}