const ModalState = {
    Confirmation : false,
    ReturnResponse : false,
    ShowFile : false,
    Profile : false,
    RecoverEmail : false,
    EditBtn : false,
    TextProduct : false,
    GeneratorLink : false,
    EditCategory : false,
    HighlightCategory: false,
    Purchase : false
};

const ModalData = {
    Confirmation : {},
    ReturnResponse : {},
    ShowFile : {},
    Profile : {},
    RecoverEmail : {},
    EditBtn : {},
    TextProduct : {},
    GeneratorLink : {},
    HighlightCategory: {},
    Purchase : {}
}

const NotifyModalObserver = {
    Confirmation : [],
    ReturnResponse : [],
    ShowFile : [],
    Profile : [],
    RecoverEmail : [],
    EditBtn : [],
    TextProduct : [],
    GeneratorLink : [],
    EditCategory : [],
    HighlightCategory: [],
    Purchase : []
}

const NotifyModalData = {
    Confirmation : [],
    ReturnResponse : [],
    ShowFile : [],
    Profile : [],
    RecoverEmail : [],
    EditBtn : [],
    TextProduct : [],
    GeneratorLink : [],
    EditCategory : [],
    HighlightCategory: [],
    Purchase : []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
