import React, { useState, useEffect } from "react";

import './Purchase.css';

import { SvgClose } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_Purchase } from "services/RegisterData";
import { GetDataPage } from "interface/Data";

export default function PopUP_Purchase(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ id, setId ] = useState('');
    const [ status, setStatus ] = useState(0);
    const [ date, setDate ] = useState('');
    const [ cash, setCash ] = useState('');
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ contact, setContact ] = useState('');
    const [ household, setHousehold ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');
    const [ location, setLocation ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ codeSend, setCodeSend ] = useState('');
    const [ listPurchase, setListPurchase ] = useState([]);

    function SaveData(value){
        if(value === 1){
            if(codeSend !=''){
                props.setLoading(true);
                Reg_Purchase(id, value, codeSend, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
            }else {
                SetModalData('ReturnResponse', { "page": "missingData", "text": 'É necessário preencher o campo "Código de envio" para finalizar o pedido!' });
                SetModalState('ReturnResponse', true);
            }
        }else {
            props.setLoading(true);
            Reg_Purchase(id, value, codeSend, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function InitialData(origin, type){
        const newData = GetDataPage('purchase').find(item => item.id == modalData);
        if(origin === 'product'){
            return newData[type];
        }else {
            const dataUser = GetDataPage('access_site').find(item => item.id == newData['id_user']);
            return dataUser[type];
        }
    }

    function CallbackSuccess(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setId('');
        setStatus(0);
        setDate('');
        setCash('');
        setName('');
        setEmail('');
        setContact('');
        setHousehold('');
        setPostalCode('');
        setLocation('');
        setDistrict('');
        setCodeSend('');
        setListPurchase([]);
        SetModalState('Purchase', false);
    }

    useEffect(()=>{
        RegisterModalData('Purchase', setModaldata);
        RegisterModalObserver('Purchase', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setId(InitialData('product', 'id'));
            setStatus(InitialData('product', 'status'));
            setDate(InitialData('product', 'date'));
            setCash(InitialData('product', 'cash_total'));
            setListPurchase(InitialData('product', 'list'));
            setCodeSend(InitialData('product', 'code_send'));

            setName(InitialData('access_site', 'name'));
            setEmail(InitialData('access_site', 'email'));
            setContact(InitialData('access_site', 'contact'));
            setHousehold(InitialData('access_site', 'household'));
            setPostalCode(InitialData('access_site', 'postal_code'));
            setLocation(InitialData('access_site', 'location'));
            setDistrict(InitialData('access_site', 'district'));

        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all Purchase">
                        <div className="div_data type_div">
                            <div className="title">
                                Lista dos produtos
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="data_purchase">
                                    <div className="inf_user">
                                        <div className=""><b>Nome: </b>{ name }</div>
                                        <div className=""><b>Email: </b>{ email }</div>
                                        <div className=""><b>Contato: </b>{ contact }</div>
                                        <div className=""><b>Morada: </b>{ household }</div>
                                        <div className=""><b>Código postal: </b>{ postalCode }</div>
                                        <div className=""><b>Localidade: </b>{ location }</div>
                                        <div className=""><b>Distrito: </b>{ district }</div>
                                        <div className=""><b>Compra realizada na data: </b>{ date }</div>
                                        <div className=""><b>Valor: </b>{ cash }</div>
                                        <div className=""><b>Status do pagamento: </b>
                                            { status === 0 ? "Pendente" : status == 1 ? "Finalizado" : "Cancelado" }
                                        </div>
                                        {
                                            status === 2 ? null :
                                            <div className="">
                                                <b>Código de envio: </b>
                                                {
                                                    status === 0 ?
                                                    <input type="text" onChange={ (e)=>{ setCodeSend(e.target.value) } } value={ codeSend } />
                                                    : codeSend
                                                }
                                            </div>
                                        }
                                        {
                                            status === 0 ?
                                            <div className="div_btn">
                                                <div className="register_data finished" onClick={ ()=>{ SaveData(1) } }>
                                                    Finalizado
                                                </div>
                                                <div className="register_data canceled" onClick={ ()=>{ SaveData(2) } }>
                                                    Cancelado
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="list_purchase">
                                        {
                                            listPurchase.map((elem, index)=>{
                                                return(
                                                    <div className="div_inf_purchase" key={ index }>
                                                        <div className="">
                                                            <b>Nome do produto: </b>
                                                            { elem.name }
                                                        </div>
                                                        {
                                                            elem.flavor != "" ? 
                                                            <div className="">
                                                                <b>Sabor: </b>{ elem.flavor }
                                                            </div>
                                                            : null
                                                        }
                                                        <div className="">
                                                            <b>Valor unitário: </b>
                                                            { elem.cash } | 
                                                            <b> Qtd: </b>
                                                            { elem.qtd } 
                                                        </div>
                                                        <div className=""><b>Total: </b>{ elem.total }</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
