import React, { useState, useEffect } from "react";

import './EditCategory.css';

import { SvgClose } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";
import { Reg_EditCategory } from "services/RegisterData";

export default function PopUP_EditCategory(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ listCategory, setListCategory ] = useState([]);

    function SaveData(){
        props.setLoading(true);
        Reg_EditCategory(listCategory, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('EditCategory', false);
    }

    function HandleData(index, value){
        const newData = [...listCategory];
        newData[index]['highlight'] = value;
        setListCategory(newData)
    }

    useEffect(()=>{
        RegisterModalData('EditCategory', setModaldata);
        RegisterModalObserver('EditCategory', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setListCategory(modalData.list_category);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all EditCategory">
                        <div className="div_data type_div">
                            <div className="title">
                                Lista das categorias
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th align="center" width="20">#</th>
                                            <th align="left" className="td_email">Nome</th>
                                            <th align="left" className="" width="80">Destacar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listCategory.map((elem, index)=>{
                                                console.log(elem);
                                                return(
                                                    <tr key={ index }>
                                                        <td>{ index + 1 }</td>
                                                        <td align="left">{ elem.name }</td>
                                                        <td align="left">
                                                            <select name="" onChange={ (e)=>{ HandleData(index, e.target.value) } } value={ elem.highlight }>
                                                                <option value="0">Não</option>
                                                                <option value="1">Sim</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>

                                <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                    Salvar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
