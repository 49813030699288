import React, { useState, useEffect } from "react";

import './Text.css';

import { SvgClose } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Reg_Text } from "services/RegisterData";

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

export default function PopUP_Text(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ text, setText ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ shippingFee, setShippingFee ] = useState('');
    const [ limitFee, setLimitFee ] = useState('');

    function SaveData(){
        props.setLoading(true);
        Reg_Text(modalData.type, shippingFee, limitFee, text, title, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        ClosePopUp();
    }

    function CallbackError(){
        props.setLoading(false);
        ClosePopUp();
    }

    function ClosePopUp(){
        props.setLoading(false);
        SetModalState('TextProduct', false);
    }

    useEffect(()=>{
        RegisterModalData('TextProduct', setModaldata);
        RegisterModalObserver('TextProduct', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setText(modalData.text);
            setTitle(modalData.title);
            setShippingFee(modalData.shipping_fee);
            setLimitFee(modalData.limit_fee)
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_text">
                        <div className="div_data type_div">
                            <div className="title">
                                Texto em { modalData.type }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="shippingFee" onChange={ (e)=>{ setShippingFee(e.target.value) } } value={ shippingFee } />
                                        <span className="name_input">Valor do frete</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="shippingFee" onChange={ (e)=>{ setLimitFee(e.target.value) } } value={ limitFee } />
                                        <span className="name_input">Valor do mínimo</span>
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } />
                                        <span className="name_input">Título</span>
                                    </div>
                                </div>

                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                                    </div>
                                </div>

                                <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
