import { useState, useEffect } from "react";

import './Products.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";
import PopUP_Text from "components/PopUp/Text";

export default function Page_Products(props){

    const [ search, setSearch ] = useState('');    
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('products'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(InitialData());

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(showPageData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    function InitialData(){
        const newData = GetDataPage('products').filter(item => item.idioma == idioma);
        return newData;
    }
    
    function SearchInput(value){
        const newData   = GetDataPage('products').filter(item => item.idioma == idioma);
        const newList   = [];
        if(value){
            newData.forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.supplier_name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.supplier_email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(newData);
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "products", "type" : "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function AltCategory(){
        SetModalData('EditCategory', { "list_category": GetDataPage("products_category").filter(item => item.type == 0) });
        SetModalState('EditCategory', true);
    }

    function PageText(){
        let text  = '';
        let title = '';
        switch (idioma) {
            case "pt_pt":
                    text  = GetDataPage('products_text')[0].text_pt_pt;
                    title = GetDataPage('products_text')[0].title_pt_pt;
                break;
            case "pt_br":
                    text  = GetDataPage('products_text')[0].text_pt_br;
                    title = GetDataPage('products_text')[0].title_pt_br;
                break;
            case "en":
                    text  = GetDataPage('products_text')[0].text_en;
                    title = GetDataPage('products_text')[0].title_en;
                break;
            case "es":
                    text  = GetDataPage('products_text')[0].text_es;
                    title = GetDataPage('products_text')[0].title_es;
                break;
        }
        SetModalData('TextProduct', { "type": idioma, "shipping_fee": GetDataPage('products_text')[0].shipping_fee, "limit_fee": GetDataPage('products_text')[0].limit_fee, "text" : text, "title": title });
        SetModalState('TextProduct', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'products_details')
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPage', setCurrentPage);
        RegisterDataPage('products', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterDataPage('products', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setShowPageData(InitialData());
    }, [currentPage, dataPage, idioma]);

    return(
        <div className="Page_Products">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Produtos</div>
                <div className="list_opt_alt_page">
                    <div className="div_add_new_data div_text" onClick={ ()=>{ AltCategory() } }>
                        Destaque categoria
                    </div>
                    <div className="div_add_new_data div_text" onClick={ ()=>{ PageText() } }>
                        Texto
                    </div>
                    <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                        <div className="new_data_add">
                            <SvgAddNewData color="#ffffff" className="icons" />
                        </div>
                        <div className="name_btn_add">produto</div>
                    </div>
                </div>
            </div>

            <div className="type_data_fixed">
                <div className="" />                
                <div className="type_idioma">
                    <div className={ idioma == "pt_br" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'pt_br') } }>pt_br</div>
                    <div className={ idioma == "pt_pt" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'pt_pt') } }>pt_pt</div>
                    <div className={ idioma == "es" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'es') } }>es</div>
                    <div className={ idioma == "en" ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ SetListPag('idioma', 'en') } }>en</div>
                </div>                
            </div>
            
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="200">Fornecedor</th>
                            <th width="80">Slide show</th>
                            <th width="60">Valor</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.name }</td>
                                        <td>{ elem.supplier_name }</td>
                                        <td align="center">{ elem.slide_show }</td>
                                        <td>{ elem.cash }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div data-tooltip-id={ "user_delete_" + elem.id } data-tooltip-content="Deletar bloco" data-tooltip-place="top" onClick={ ()=>{ DeleteData(elem.id, elem.name) } } title="Deletar bloco">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <Tooltip id={ "user_delete_" + elem.id } />
                                                <div data-tooltip-id={ "user_edit_" + elem.id } data-tooltip-content="Editar bloco" data-tooltip-place="top" onClick={ ()=>{ PageClick(elem.id) } } title="Editar bloco">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "user_edit_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUP_Text setLoading={ props.setLoading } />
        </div>
    )
}