import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData, UnRegisterUserPage } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag, UnRegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgCash, SvgDashboard, SvgDashboardExit, SvgDownload, SvgEmail, SvgHealth, SvgMenuHamburguer, SvgMenuPoint, SvgProducts, SvgQuestions, SvgSetaRight, SvgSite, SvgUser, SvgUserDash } from 'components/SvgFile';

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from 'interface/Data';

import Page_Users from 'components/Page/Users';
import Page_Users_Details from 'components/Page/Users/Details';

import Page_Questions from 'components/Page/Questions';
import Page_Questions_Details from 'components/Page/Questions/Details';

import Page_Home from 'components/Page/Home';
import Page_Home_Details from 'components/Page/Home/Details';

import Page_Questionnaire from 'components/Page/Questionnaire';

import Page_Config from 'components/Page/Config';

import Page_Health from 'components/Page/Health';
import Page_Health_Details from 'components/Page/Health/Details';

import Page_About_Details from 'components/Page/About/Details';
import Page_WhatWeDo_Details from 'components/Page/WhatWeDo/Details';

import Page_About from 'components/Page/About';
import Page_WhatWeDo from 'components/Page/WhatWeDo';

import Page_Products from 'components/Page/Products';
import Page_Products_Details from 'components/Page/Products/Details';

import Page_Emails from 'components/Page/Emails';

import Page_UsersSite from 'components/Page/UsersSite';
import Page_UsersSite_Details from 'components/Page/UsersSite/Details';

import Page_Purchase from 'components/Page/Purchase';

export default function Contents(props){

    const cookies = new Cookies();

    const [ status, setStatus ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ infPurchase, setPurchase ] = useState(GetDataPage('purchase'));
    

    const [ statusPurchase, setStatusPurchase ] = useState(CheckedPurchase());
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CheckedPurchase(){
        const newData = GetDataPage('purchase').find(item => item.status == 0);
        if(newData){
            return true;
        }
        return false;
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if(document.body.clientWidth <= 640){
            setStatus(false);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 640){
                setStatus(false);
            }
        });
    }

    function CurrentPage(){
        window.history.pushState("", "", "/");
        switch(currentPage) {
            case "access_dash":
                return <Page_Users CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "access_dash_details":
                return <Page_Users_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "access_site":
                return <Page_UsersSite CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "access_site_details":
                return <Page_UsersSite_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "page_home":
                return <Page_Home CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "page_home_details":
                return <Page_Home_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "page_about":
                return <Page_About CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "page_about_details":
                return <Page_About_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;
                
            case "page_whatWeDo":
                return <Page_WhatWeDo CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "page_whatWeDo_details":
                return <Page_WhatWeDo_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "questions":
                return <Page_Questions CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "questions_details":
                return <Page_Questions_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "questionnaire":
                return <Page_Questionnaire CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "health":
                return <Page_Health CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "health_details":
                return <Page_Health_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "products":
                return <Page_Products CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "products_details":
                return <Page_Products_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "purchase": 
                return <Page_Purchase CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "email":
                return <Page_Emails CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            default:
                return <Page_Config CkickPage={ CkickPage } setLoading={ props.setLoading } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    useEffect(()=>{
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterListPag('currentPage', setCurrentPage);
        RegisterDataPage('purchase', setPurchase);

        return ()=>{            
            UnRegisterUserPage('file', setUserFile);
            UnRegisterUserPage('name', setUserName);
            UnRegisterListPag('currentPage', setCurrentPage);
            UnRegisterDataPage('purchase', setPurchase);
        };
    }, []);

    useEffect(()=>{
        CurrentPage();
    }, [currentPage]);

    useEffect(()=>{
        setStatusPurchase(CheckedPurchase());
    }, [infPurchase]);

    return(
        <div className="Contents">            
            <a href="https://famura.eu/" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>

            <div className={ status ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">

                    <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                        <div className={ status ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                            <div className="">
                                {
                                    status ?
                                    <img alt="logotipo" src="./assets/logo.png" className="logotipo2" /> :
                                    <img alt="logotipo" src="./assets/logo.png" className="logotipo" />
                                }
                            </div>
                            <div className={ status ? "icon_menu icon_menu_active" : "icon_menu" }>
                                <SvgMenuHamburguer color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>

                    <div className={ status ? "show_data_top" : "show_data_top show_data_top_close" }>
                        <div className="opt_menu_dash">
                            <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboard color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Configuração</div>
                                </div>
                            </div>

                            <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgUserDash color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Usuários dashboard</div>
                                </div>
                            </div>

                            <div className={ currentPage == "access_site" || currentPage == "access_site_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_site"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgUserDash color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Usuários site</div>
                                </div>
                            </div>

                            <div className={ currentPage == "page_home" || currentPage == "page_home_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("page_home"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgSite color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Home</div>
                                </div>
                            </div>   

                            <div className={ currentPage == "page_about" || currentPage == "page_about_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("page_about"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgSite color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Quem somos</div>
                                </div>
                            </div>  

                            <div className={ currentPage == "page_whatWeDo" || currentPage == "page_whatWeDo_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("page_whatWeDo"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgSite color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Oque fazemos</div>
                                </div>
                            </div>

                            <div className={ currentPage == "questions" || currentPage == "questions_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("questions"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgQuestions color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>FAQ</div>
                                </div>
                            </div>

                            <div className={ currentPage == "questionnaire" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("questionnaire"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgQuestions color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Questionário</div>
                                </div>
                            </div> 

                            <div className={ currentPage == "health" || currentPage == "health_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("health"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgHealth color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Consultas</div>
                                </div>
                            </div>  

                            <div className={ currentPage == "products" || currentPage == "products_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("products"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgProducts color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Produtos</div>
                                </div>
                            </div>  

                            <div className={ currentPage == "purchase" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("purchase"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgCash color="#FFC400" className="icons_dash" />
                                        {
                                            statusPurchase ? 
                                            <div className="alert" />
                                            : null
                                        }
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>
                                        Compras no site
                                    </div>
                                </div>
                            </div>  

                            <div className={ currentPage == "email" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("email"); } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgEmail color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>
                                        Texto dos e-mails
                                    </div>
                                </div>
                            </div>

                            {
                                GetDataPage('list_email').length > 0 ? 
                                <a href={ process.env.REACT_APP_API_URL + "/php/donwload_email.php" } className="show_menu_dash" target="_blank">
                                    <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <SvgDownload color="#FFC400" className="icons_dash" />
                                        </div>
                                        <div className={ status ? "name_page menu_close" : "name_page" }>
                                            Download e-mails
                                        </div>
                                    </div>
                                </a> 
                                : null
                            }

                            <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Sair</div>
                                </div>
                            </div>
                        </div>
                        <div className="div_profile">
                            <div className={ status ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                                <div className="div_img">
                                    {
                                        userFile !='' ?
                                        <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                        <SvgUser color="#FFFFFF" className="show_img_profile" />
                                    }
                                </div>
                                <div className={ status ? "name_user menu_close" : "name_user" }>
                                    { userName }
                                </div>
                                <div className={ status ? "open_profile menu_close" : "open_profile" }>
                                    <SvgMenuPoint color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
        </div>
    )
}
