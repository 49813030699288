import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgReturn, SvgSave, SvgSetaRight, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";
import { Reg_Products } from "services/RegisterData";

export default function Page_Products_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("products"));
    const [ listCategory, setListCategory ] = useState(GetDataPage("products_category"));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ idCategory, setIdCategory ] = useState(InitialData('id_category'));
    const [ newCategory, setNewCategory ] = useState('');
    const [ idSubcategory, setIdSubcategory ] = useState(InitialData('id_subcategory'));
    const [ newSubcategory, setNewSubcategory ] = useState('');

    const [ slide, setSlide ] = useState(InitialData('slide_show'));
    const [ slideFile, setSlideFile ] = useState(InitialData('slide_file'));
    const [ slideStatus, setSlideStatus ] = useState(false);

    const [ qtd, setQtd ] = useState(InitialData('qtd'));
    const [ qtdTotal, setQtdTotal ] = useState(InitialData('qtd_total'));
    const [ flavor, setFlavor ] = useState(InitialData('flavor'));

    const [ tag, setTag ] = useState(InitialData('tag'));  
    const [ name, setName ] = useState(InitialData('name'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ status, setStatus ] = useState(false);
    const [ cash, setCash ] = useState(InitialData('cash'));
    const [ text, setText ] = useState(InitialData('text'));

    const [ promotional, setPromotional ] = useState(InitialData('cash_promotional'));
    const [ supplierName, setSupplierName ] = useState(InitialData('supplier_name'));
    const [ supplierEmail, setSupplierEmail ] = useState(InitialData('supplier_email'));
    const [ supplierContact, setSupplierContact ] = useState(InitialData('supplier_contact'));

    const [ listImg, setListImg ] = useState(InitialData('list_img'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'slide_show'){
            return "Não";
        }
        if(type == "list_img" || type == "flavor" || type == "tag"){
            return [];
        }
        return '';
    }

    function AddFile(type, value){
        if(type == 'cover'){
            if(value){
                setFile(value);
                setStatus(true);
            }else {
                setFile('');
                setStatus(false);
            }
        }else {
            if(value){
                setSlideFile(value);
                setSlideStatus(true);
            }else {
                setSlideFile('');
                setSlideStatus(false);
            }
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...listImg];
            newData.splice(index, 1);
            setListImg(newData);

        }else {
            SetModalData('Confirmation', { "origin": "products", "type" : "delete_img", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, index, value){
        const newData = [...listImg];
        if(type == 'file'){
            if(value){
                newData[index]['file']   = value;
                newData[index]['status'] = true;

            }else {
                newData[index]['file']   = "";
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setListImg(newData);
    }

    function DeleteDataTag(id, index, value){
        if(id == 0){
            const newData = [...tag];
            newData.splice(index, 1);
            setTag(newData);

        }else {
            SetModalData('Confirmation', { "origin": "products", "type" : "delete_tag", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function HandleTag(type, index, value){
        const newData = [...tag];
        newData[index][type] = value;        
        setTag(newData);
    }

    function HandleFlavor(type, index, value){
        const newData = [...flavor];
        newData[index][type] = value;        
        setFlavor(newData);
    }

    function DeleteFlavor(id, index, value){
        if(id == 0){
            const newData = [...flavor];
            newData.splice(index, 1);
            setFlavor(newData);

        }else {
            SetModalData('Confirmation', { "origin": "products", "type" : "delete_flavor", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Products(idioma, userId, idPage, idCategory, tag, slide, slideFile, newCategory, idSubcategory, newSubcategory, file, name, text, cash, promotional, supplierName, supplierEmail, supplierContact, listImg, qtd, flavor, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'products');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('products', setDataPage);
        RegisterDataPage('products_category', setListCategory);

        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('products', setDataPage);
            UnRegisterDataPage('products_category', setListCategory);

            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("products"));
        setListCategory(GetDataPage("products_category"));
        setIdPage(GetListPag('currentPageId'));

        setIdCategory(InitialData('id_category'));
        setNewCategory('');
        setIdSubcategory(InitialData('id_subcategory'));
        setNewSubcategory('');
        
        setSlide(InitialData('slide_show'));
        setSlideFile(InitialData('slide_file'));
        setSlideStatus(false);

        setQtd(InitialData('qtd'));
        setQtdTotal(InitialData('qtd_total'));

        setTag(InitialData('tag'));
        setFlavor(InitialData('flavor'));

        setName(InitialData('name'));
        setFile(InitialData('file'));
        setStatus(false);
        setText(InitialData('text'));
        setCash(InitialData('cash'));
        setPromotional(InitialData('cash_promotional'));

        setSupplierName(InitialData('supplier_name'));
        setSupplierEmail(InitialData('supplier_email'));
        setSupplierContact(InitialData('supplier_contact'));
        setListImg(InitialData('list_img'))
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Products_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do produto</div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            <select className="slide_show" onChange={ (e)=>{ setSlide(e.target.value) } } value={ slide } required>
                                <option value="Sim">Sim</option>
                                <option value="Não">Não</option>
                            </select>
                            <span className="name_input">Slide show</span>
                        </div>
                        {
                            slide == 'Sim' ? 
                            <span className="div_input div_add_img">
                                <div className={ slideStatus == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { slideStatus == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ AddFile('slide_show', e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    slideFile !='' ?
                                    <>
                                        <div className="delete_img_add" onClick={ ()=>{ AddFile('slide_show', ''); } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        {
                                            slideStatus == true ?
                                            <div className="show_img_" onClick={ ()=>{ OpenFile("showImg_local", URL.createObjectURL(slideFile)) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div> :
                                            <div className="show_img_" onClick={ ()=>{ OpenFile("showImg", "products/" + slideFile) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </>
                                    : null
                                }
                            </span>
                            : null
                        }
                        {
                            flavor.length > 0 ?
                            <div className="div_input">
                                <input type="text" className="qtd" value={ qtdTotal } readOnly />
                                <span className="name_input">Quantidade em estoque</span>
                            </div>
                            :
                            <div className="div_input">
                                <input type="text" className="qtd" onChange={ (e)=>{ setQtd(e.target.value.replaceAll(' ', '')) } } value={ qtd } maxLength={ 3 } required />
                                <span className="name_input">Quantidade em estoque</span>
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <select className="" onChange={ (e)=>{ setIdCategory(e.target.value) } } value={ idCategory } required>
                                <option value="">Selecione uma opção</option>
                                <option value="addNewCategory">Cadastrar nova categoria</option>
                                {
                                    listCategory.map((elem, index)=>{
                                        if(elem.type == 0){
                                            return(
                                                <option value={ elem.id } key={ index }>{ elem.name }</option>
                                            )
                                        }
                                    })
                                }
                            </select>
                            <span className="name_input">Categoria</span>
                        </div>
                        {
                            idCategory == "addNewCategory" ?
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setNewCategory(e.target.value) } } value={ newCategory } maxLength="140" required />
                                <span className="name_input">Nome da nova categoria</span>
                            </div> : null
                        }
                        <div className="div_input space_div">
                            <select className="" onChange={ (e)=>{ setIdSubcategory(e.target.value) } } value={ idSubcategory } required>
                                <option value="">Selecione uma opção</option>
                                <option value="addNewSubcategory">Cadastrar nova subcategoria</option>
                                {
                                    listCategory.map((elem, index)=>{
                                        if(elem.type == 1){
                                            return(
                                                <option value={ elem.id } key={ index }>{ elem.name }</option>
                                            )
                                        }
                                    })
                                }
                            </select>
                            <span className="name_input">Subcategoria</span>
                        </div>
                        {
                            idSubcategory == "addNewSubcategory" ?
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ setNewSubcategory(e.target.value) } } value={ newSubcategory } maxLength="140" required />
                                <span className="name_input">Nome da nova subcategoria</span>
                            </div> : null
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="promotional" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } maxLength="140" />
                            <span className="name_input">Valor</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="promotional" onChange={ (e)=>{ setPromotional(e.target.value) } } value={ promotional } maxLength="140" />
                            <span className="name_input">Promoção</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Capa adicionada" : "Adicionar capa" }
                                    <input type="file" onChange={ (e)=>{ AddFile('cover', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('cover', ''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile("showImg_local", URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile("showImg", "products/" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>

                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Tags</div>
                        <div className="new_block_text" onClick={ ()=>{ setTag([...tag, { "id": 0, "id_tag": "", "new_tag": "" }]) } }>Nova tag</div>
                    </div>
                    {
                        tag.length > 0 ?
                        tag.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">                                        
                                        <select className="" onChange={ (e)=>{ HandleTag('id_tag', index, e.target.value) } } value={ elem.id_tag }>
                                            <option value="">#</option>
                                            <option value="newTag">Adicionar nova tag</option>
                                            {
                                                listCategory.map((elem_1, index_1)=>{
                                                    if(elem_1.type == 2){
                                                        return(
                                                            <option value={ elem_1.id } key={ index_1 }>{ elem_1.name }</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                        <span className="name_input">Tag</span>
                                    </div>
                                    {
                                        elem.id_tag == "newTag" ? 
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleTag('new_tag', index, e.target.value) } } value={ elem.new_tag } maxLength="140" />
                                            <span className="name_input">Nome da nova tag</span>
                                        </div>
                                        : null
                                    }
                                    <div className="div_input div_icon" onClick={ ()=>{ DeleteDataTag(elem.id, index, "tag - " + elem.name_tag) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        }) : 
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                Nenhuma tag adicionada...
                            </div>
                        </div>
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Sabor do produto</div>
                        <div className="new_block_text" onClick={ ()=>{ setFlavor([...flavor, { "id": 0, "id_flavor": "", "new_flavor": "", "qtd": 0 }]) } }>Novo sabor</div>
                    </div>
                    {
                        flavor.length > 0 ?
                        flavor.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">                                        
                                        <select className="" onChange={ (e)=>{ HandleFlavor('id_flavor', index, e.target.value) } } value={ elem.id_flavor }>
                                            <option value="">#</option>
                                            <option value="newFlavor">Adicionar novo sabor</option>
                                            {
                                                listCategory.map((elem_1, index_1)=>{
                                                    if(elem_1.type == 3){
                                                        return(
                                                            <option value={ elem_1.id } key={ index_1 }>{ elem_1.name }</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                        <span className="name_input">Sabor</span>
                                    </div>
                                    {
                                        elem.id_flavor == "newFlavor" ? 
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ HandleFlavor('new_flavor', index, e.target.value) } } value={ elem.new_flavor } maxLength="140" />
                                            <span className="name_input">Nome do novo sabor</span>
                                        </div>
                                        : null
                                    }
                                    <div className="div_input">
                                        <input type="text" className="flavor_qtd" onChange={ (e)=>{ HandleFlavor('qtd', index, e.target.value.replaceAll(' ', '')) } } value={ elem.qtd } maxLength="3" />
                                        <span className="name_input">Qtd</span>
                                    </div>
                                    <div className="div_input div_icon" onClick={ ()=>{ DeleteFlavor(elem.id, index, "Sabor - " + elem.name_flavor) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        }) : 
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                Nenhum sabor adicionado...
                            </div>
                        </div>
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do fornecedor</div>
                    </div>                    
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setSupplierName(e.target.value) } } value={ supplierName } maxLength="140" />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setSupplierEmail(e.target.value) } } value={ supplierEmail } maxLength="140" />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setSupplierContact(e.target.value) } } value={ supplierContact } maxLength="140" />
                            <span className="name_input">Contato</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Galeria de imagens do produto</div>
                        <div className="new_block_text" onClick={ ()=>{ setListImg([...listImg, { "id": 0, "name": "", "file": "", "status": false }]) } }>Nova imagem</div>
                    </div>
                    {
                        listImg.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('name', index, e.target.value) } } value={ elem.name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            elem.file !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                {
                                                    elem.status == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile("showImg_local", URL.createObjectURL(elem.file)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile("showImg", "products/" + elem.file) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </span>
                                    <div className="div_input div_icon" onClick={ ()=>{ DeleteData(elem.id, index, elem.name) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}