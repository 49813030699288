import React, { useState, useEffect } from "react";

import './GeneratorLink.css';

import { SvgClose } from "components/SvgFile";
import { GetUserData, RegisterUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_GeneratorLink } from "services/RegisterData";

export default function PopUP_GeneratorLink(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ type, setType ] = useState('');
    const [ dateInitial, setDateInitial ] = useState('');
    const [ cash, setCash ] = useState('');
    const [ frequency, setFrequency ] = useState('');
    const [ paymentMethod, setPaymentMethod ] = useState('');
    const [ datePayment, setDatePayment ] = useState('');

    const [ methodIban, setMethodIban ] = useState('');
    const [ methodName, setMethodName ] = useState('');
    const [ methodEmail, setMethodEmail ] = useState('');
    const [ methodPhone, setMethodPhone ] = useState('');
    const [ methodTitle, setMethodTitle ] = useState('');
    const [ idAccount, setIdAccount ] = useState('');
    const [ retries, setRetries ] = useState('');
    const [ taxNumber, setTaxNumber ] = useState('');
    const [ areaPhone, setAreaPhone ] = useState('');

    const newData = new Date();
    const currentData = newData.getFullYear() + "-" + CurrentData('month') + "-" + CurrentData('day');
    
    function CurrentData(type){
        if(type == 'month'){
            let month = newData.getMonth() + 1;
            if(month > 9){
                month = month;
            }else {
                month = "0" + month;
            }
            return month;
        }
        if(type == "day"){
            let day = 0;
            if(newData.getDate() > 9){
                day = newData.getDate();
            }else {
                day = "0" + newData.getDate();
            }
            return day;
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_GeneratorLink(userId, modalData.idPage, modalData.type, idAccount, retries, dateInitial, cash, frequency, paymentMethod, datePayment, methodIban, methodName, methodEmail, methodPhone, methodTitle, areaPhone, taxNumber, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);

        SetModalState('GeneratorLink', false);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "Erro ao gerar link de pagamento, tente novamente!"  });
        SetModalState('ReturnResponse', true);

        SetModalState('GeneratorLink', false);
    }

    function ClosePopUp(){
        props.setLoading(false);
        setType('');
        setDateInitial('');
        setCash('');
        setFrequency('');
        setPaymentMethod('');
        
        SetModalState('GeneratorLink', false);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('GeneratorLink', setModaldata);
        RegisterModalObserver('GeneratorLink', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setType(modalData.type);
            setDateInitial(modalData.dataInitial);
            setCash(modalData.cash);
            setFrequency(modalData.frequency);
            setPaymentMethod(modalData.paymentMethod);
            setDatePayment(modalData.datePayment);

            setMethodIban(modalData.methodIban);
            setMethodName(modalData.methodName);
            setMethodEmail(modalData.methodEmail);
            setMethodPhone(modalData.methodPhone);
            setMethodTitle(modalData.methodTitle);
            setIdAccount(modalData.idAccount);
            setRetries(modalData.retries);
            setTaxNumber(modalData.taxNumber);
            setAreaPhone(modalData.areaPhone);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <form className="PopUp" onSubmit={ SaveData }>
                    <div className="all popup_generator_link">
                        <div className="div_data type_div">
                            <div className="title">
                                Gerar link de pagamento
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">                                    
                                    <div className="div_input">
                                        <input type="date" className="date_health" onChange={ (e)=>{ setDateInitial(e.target.value) } } value={ dateInitial } required />
                                        <span className="name_input">Data da consulta</span>
                                    </div>
                                    
                                    <div className="div_input">
                                        <input type="text" className="cash" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } />
                                        <span className="name_input">Valor</span>
                                    </div>
                                    
                                    <div className="div_input">
                                        <select className="hour_health" onChange={ (e)=>{ setFrequency(e.target.value) } } value={ frequency }>
                                            <option value="">#</option>
                                            <option value="1D">1D</option>
                                            <option value="1W">1W</option>
                                            <option value="2W">2W</option>
                                            <option value="1M">1M</option>
                                            <option value="2M">2M</option>
                                            <option value="3M">3M</option>
                                            <option value="4M">4M</option>
                                            <option value="6M">6M</option>
                                            <option value="1Y">1Y</option>
                                            <option value="2Y">2Y</option>
                                            <option value="3Y">3Y</option>
                                        </select>
                                        <span className="name_input">Frequência</span>
                                    </div>
                                    <div className="div_input">
                                        <select className="frequency" onChange={ (e)=>{ setPaymentMethod(e.target.value) } } value={ paymentMethod }>
                                            <option value="">#</option>
                                            <option value="cc">cc</option>
                                            <option value="dd">dd</option>
                                        </select>
                                        <span className="name_input">Método de pagamento</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="date" className="date_health" onChange={ (e)=>{ setDatePayment(e.target.value) } } value={ datePayment } />
                                        <span className="name_input">Data da cobrança</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="retries" maxLength="4" onChange={ (e)=>{ setRetries(e.target.value) } } value={ retries } />
                                        <span className="name_input">Retries</span>
                                    </div>
                                    {
                                        paymentMethod == "cc" ? 
                                        <div className="div_input">
                                            <input type="text" className="" onChange={ (e)=>{ setIdAccount(e.target.value) } } value={ idAccount } />
                                            <span className="name_input">id da conta</span>
                                        </div> : null
                                    }
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setMethodName(e.target.value) } } value={ methodName } />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="email" className="" onChange={ (e)=>{ setMethodEmail(e.target.value) } } value={ methodEmail } />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="area_phone" onChange={ (e)=>{ setAreaPhone(e.target.value) } } value={ areaPhone } maxLength={ 6 } />
                                        <span className="name_input">Área</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setMethodPhone(e.target.value) } } value={ methodPhone } />
                                        <span className="name_input">Celular</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ setMethodTitle(e.target.value) } } value={ methodTitle } />
                                        <span className="name_input">Titular da conta</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setTaxNumber(e.target.value) } } value={ taxNumber } maxLength={ 140 } />
                                        <span className="name_input">Número fiscal</span>
                                    </div>
                                </div>
                                {
                                    paymentMethod == "cc" ? null :
                                    <div className="list_input_data">
                                        <div className="div_input">
                                            <input type="text" className="iban" onChange={ (e)=>{ setMethodIban(e.target.value) } } value={ methodIban } />
                                            <span className="name_input">Iban</span>
                                        </div>
                                    </div>
                                }
                                { 
                                    datePayment >= currentData ?
                                    <button className="register_data button_link">
                                        Gerar Link
                                    </button> : 
                                    <div className="register_data button_link">
                                        A data precisa ser igual ou maior q a data atual
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </>
        : <React.Fragment></React.Fragment>)
    );
}