import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgReturn, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Question } from "services/RegisterData";

export default function Page_Questions_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("questions"));

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ question, setQuestion ] = useState(InitialData('question'));
    const [ response, setResponse ] = useState(InitialData('response'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Question(idioma, userId, idPage, question, response, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'questions');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('questions', setDataPage);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('questions', setDataPage);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("questions"));
        setIdPage(GetListPag('currentPageId'));

        setQuestion(InitialData('question'));
        setResponse(InitialData('response'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Questions_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setQuestion(e.target.value) } } value={ question } maxLength="140" required />
                            <span className="name_input">Pergunta</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Resposta</div>
                            <JoditEditor config={ config } value={ response ? response.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setResponse(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}