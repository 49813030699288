import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        switch (origin) {
            case "page_home": case "page_about": case "page_whatWeDo":
                    SetListDataSingle('page_site', response.data['page_site']);
                break;
        
            default:
                    SetListDataSingle(origin, response.data[origin]);
                break;
        }
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Change position
export function Reg_AltPosition(current_page, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', current_page);
    data.append('edit_data', 'alt_position');

    data.append('id', id);
    data.append('order_', order);
    data.append('type', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle(current_page, response.data[current_page]);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data    = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}


// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, note, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('note', note);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }
            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit or login 
export function Reg_UserSite(userId, idPage, name, email, contact, household, postalCode, location, district, password, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_site');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('contact', contact);
    data.append('household', household);
    data.append('postal_code', postalCode);
    data.append('location', location);
    data.append('district', district);
    data.append('password', password);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('access_site', response.data.access_site);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'accessDash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('accessDash', response.data.accessDash);
        const newData = response.data.accessDash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// new password
export function Reg_NewPass(password, code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'registe_new_pass');

    data.append('password', password);
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        if(response.data == 'conectado'){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}
// checked code
export function Reg_CodeNewPass(code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'registe_checked');
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        if(response.data == 'conectado'){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// question
export function Reg_Question(idioma, userId, idPage, nameBtn, question, response, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'questions');
    data.append('edit_data', 'edit_or_register');

    data.append('idioma', idioma);
    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name_btn', nameBtn);
    data.append('question', question);
    data.append('response', response);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('questions', response.data.questions);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// edit btn
export function Reg_EditBtn(name, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'questions');
    data.append('edit_data', 'edit_btn');

    data.append('name_btn', name);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('config', response.data.config);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// site - home
export function Reg_PageHome(idioma, userId, idPage, btn, name_btn, color, type, titlePage, title, subtitle, typeVideo, video, text, fileAlign, file, cardData, cardIcons, arrowData, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'page_home');
    data.append('edit_data', 'edit_or_register');

    data.append('idioma', idioma);
    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('btn', btn);
    data.append('name_btn', name_btn);
    data.append('color', color);
    data.append('type', type);
    data.append('title_page', titlePage);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('text', text);
    data.append('file_align', fileAlign);
    data.append('file', file);

    // card with image
    cardData.forEach((elem, index) => {
        data.append('card_id[]', elem.id);
        data.append('card_title[]', elem.title);
        data.append('card_color[]', elem.color);
        data.append('card_btn[]', elem.btn);
        data.append('card_text[]', elem.text);
        data.append('card_file_' + index, elem.file);
    });

    // card with icon
    cardIcons.forEach((elem, index) => {
        data.append('card_icons_id[]', elem.id);
        data.append('card_icons_text[]', elem.text);
        data.append('card_icons_file_' + index, elem.file);
    });

    // arrow
    arrowData.forEach((elem, index) => {
        data.append('arrow_id[]', elem.id);
        data.append('arrow_title[]', elem.title);
        data.append('arrow_color[]', elem.color);
        data.append('arrow_text[]', elem.text);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('page_site', response.data.page_site);        
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// site - about
export function Reg_PageAbout(idioma, userId, idPage, btn, name_btn, color, type, titlePage, title, subtitle, typeVideo, video, text, fileAlign, file, cardData, cardIcons, arrowData, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'page_about');
    data.append('edit_data', 'edit_or_register');

    data.append('idioma', idioma);
    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('btn', btn);
    data.append('name_btn', name_btn);
    data.append('color', color);
    data.append('type', type);
    data.append('title_page', titlePage);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('text', text);
    data.append('file_align', fileAlign);
    data.append('file', file);

    // card with image
    cardData.forEach((elem, index) => {
        data.append('card_id[]', elem.id);
        data.append('card_title[]', elem.title);
        data.append('card_color[]', elem.color);
        data.append('card_btn[]', elem.btn);
        data.append('card_text[]', elem.text);
        data.append('card_file_' + index, elem.file);
    });

    // card with icon
    cardIcons.forEach((elem, index) => {
        data.append('card_icons_id[]', elem.id);
        data.append('card_icons_text[]', elem.text);
        data.append('card_icons_file_' + index, elem.file);
    });

    // arrow
    arrowData.forEach((elem, index) => {
        data.append('arrow_id[]', elem.id);
        data.append('arrow_title[]', elem.title);
        data.append('arrow_color[]', elem.color);
        data.append('arrow_text[]', elem.text);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('page_site', response.data.page_site);   
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// site - what We Do
export function Reg_PageWhatWeDo(idioma, userId, idPage, btn, name_btn, color, type, titlePage, title, subtitle, typeVideo, video, text, fileAlign, file, cardData, cardIcons, arrowData, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'page_whatWeDo');
    data.append('edit_data', 'edit_or_register');

    data.append('idioma', idioma);
    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('btn', btn);
    data.append('name_btn', name_btn);
    data.append('color', color);
    data.append('type', type);
    data.append('title_page', titlePage);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('text', text);
    data.append('file_align', fileAlign);
    data.append('file', file);

    // card with image
    cardData.forEach((elem, index) => {
        data.append('card_id[]', elem.id);
        data.append('card_title[]', elem.title);
        data.append('card_color[]', elem.color);
        data.append('card_btn[]', elem.btn);
        data.append('card_text[]', elem.text);
        data.append('card_file_' + index, elem.file);
    });

    // card with icon
    cardIcons.forEach((elem, index) => {
        data.append('card_icons_id[]', elem.id);
        data.append('card_icons_text[]', elem.text);
        data.append('card_icons_file_' + index, elem.file);
    });

    // arrow
    arrowData.forEach((elem, index) => {
        data.append('arrow_id[]', elem.id);
        data.append('arrow_title[]', elem.title);
        data.append('arrow_color[]', elem.color);
        data.append('arrow_text[]', elem.text);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('page_site', response.data.page_site);   
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// site - products
export function Reg_Products(idioma, userId, idPage, idCategory, tag, slide, slideFile, newCategory, idSubcategory, newSubcategory, file, name, text, cash, promotional, supplierName, supplierEmail, supplierContact, listImg, qtd, flavor, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'products');
    data.append('edit_data', 'edit_or_register');

    data.append('idioma', idioma);
    data.append('userId', userId);
    data.append('idPage', idPage);
    
    data.append('id_category', idCategory);
    data.append('new_category', newCategory);
    data.append('id_subcategory', idSubcategory);
    data.append('new_subcategory', newSubcategory);
    
    data.append('qtd', qtd);

    data.append('slide_show', slide)
    data.append('slide_file', slideFile)

    data.append('file', file);
    data.append('name', name);
    data.append('text', text);
    data.append('cash', cash);
    data.append('promotional', promotional);

    data.append('supplier_name', supplierName);
    data.append('supplier_email', supplierEmail);
    data.append('supplier_contact', supplierContact);

    // flavor
    flavor.forEach((elem, index) => {
        data.append('flavor_id[]', elem.id);
        data.append('flavor_id_flavor[]', elem.id_flavor);
        data.append('flavor_new_flavor[]', elem.new_flavor);
        data.append('flavor_qtd[]', elem.qtd);        
    });

    // tag
    tag.forEach((elem, index) => {
        data.append('tag_id[]', elem.id);
        data.append('tag_id_tag[]', elem.id_tag);
        data.append('tag_new_tag[]', elem.new_tag);
    });

    // file
    listImg.forEach((elem, index) => {
        data.append('list_img_id[]', elem.id);
        data.append('list_img_name[]', elem.name);
        data.append('list_img_file_' + index, elem.file);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('products', response.data.products);   
        SetListDataSingle('products_category', response.data.products_category);   
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// text product
export function Reg_Text(type, shippingFee, limitFee, text, title, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'products');
    data.append('edit_data', 'text');

    data.append('type', type);
    data.append('shipping_fee', shippingFee);
    data.append('limit_fee', limitFee);
    data.append('text', text);
    data.append('title', title);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('products_text', response.data.products_text);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// questionnaire
export function Reg_Questionnaire(userId, idioma, idPage, title, text, list, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'questionnaire');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idioma', idioma);
    data.append('idPage', idPage);
    
    data.append('title', title);
    data.append('text', text);
    
    list.forEach((elem, index) => {
        data.append('contents_id[]', elem.id);
        data.append('contents_text[]', elem.text);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('questionnaire', response.data.questionnaire);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// health
export function Reg_Health(userId, idPage, status, retries, name, email, contact, date, hourHealth, obs, question, cash, statusPayment, frequency, paymentMethod, datePayment, methodIban, methodName, methodEmail, methodPhone, methodTitle, idAccount, areaPhone, taxNumber, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'health');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    
    data.append('status', status);
    data.append('retries', retries);
    data.append('name', name);
    data.append('email', email);
    data.append('contact', contact);
    data.append('date', date);
    data.append('hourHealth', hourHealth);
    data.append('obs', obs);
    
    data.append('cash', cash);
    data.append('status_payment', statusPayment);
    data.append('frequency', frequency);
    data.append('payment_method', paymentMethod);
    data.append('date_payment', datePayment);
    
    data.append('method_iban', methodIban);
    data.append('method_name', methodName);
    data.append('method_email', methodEmail);
    data.append('method_phone', methodPhone);
    data.append('method_title', methodTitle);
    data.append('id_account', idAccount);
    data.append('area_phone', areaPhone);
    data.append('tax_number', taxNumber);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('health', response.data.health);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// generator link
export function Reg_GeneratorLink(userId, idPage, type, idAccount, retries, dateInitial, cash, frequency, paymentMethod, datePayment, methodIban, methodName, methodEmail, methodPhone, methodTitle, areaPhone, taxNumber, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'health');
    data.append('edit_data', 'generator_link');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('type', type);
    data.append('dateInitial', dateInitial);

    data.append('id_account', idAccount);
    data.append('retries', retries);
    data.append('cash', cash);
    data.append('frequency', frequency);
    data.append('payment_method', paymentMethod);
    data.append('date_payment', datePayment);
    
    data.append('method_iban', methodIban);
    data.append('method_name', methodName);
    data.append('method_email', methodEmail);
    data.append('method_phone', methodPhone);
    data.append('method_title', methodTitle);
    data.append('area_phone', areaPhone);
    data.append('tax_number', taxNumber);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('health', response.data.health);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// idioma dashboard
export function Reg_IdiomaDash(userId, origin, idioma, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'config');
    data.append('edit_data', origin);

    data.append('userId', userId);
    data.append('idioma', idioma);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('config', response.data.config);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// config
export function Reg_Config(userId, week_start, week_end, hour_start, hour_end, menuText, menuContact, contactEmail, contactNumber, contactAddress, termsConditions, privacyPolicy, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'config');
    data.append('edit_data', 'text_fixed');

    data.append('userId', userId);

    data.append('week_start', week_start);
    data.append('week_end', week_end);

    data.append('hour_start', hour_start);
    data.append('hour_end', hour_end);
    
    data.append('menu_text', menuText);
    data.append('menu_contact', menuContact);
    
    data.append('contact_email', contactEmail);
    data.append('contact_number', contactNumber);
    data.append('contact_address', contactAddress);

    data.append('terms_conditions', termsConditions);
    data.append('privacy_policy', privacyPolicy);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('config', response.data.config);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// edit category
export function Reg_EditCategory(listCategory, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'config');
    data.append('edit_data', 'edit_category');

    listCategory.forEach((elem, index) => {
        data.append('category_id[]', elem.id);
        data.append('category_highlight[]', elem.highlight);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('products_category', response.data.products_category);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Reg_Emails
export function Reg_Emails(userId, idioma, listEmails, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'emails');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idioma', idioma);

    listEmails.forEach((elem, index) => {
        data.append('id[]', elem.id);
        data.append('text[]', elem.text);
        data.append('file_' + index, elem.file);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('emails', response.data.emails);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// edit purchase
export function Reg_Purchase(id, status, codeSend, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'purchase');
    data.append('edit_data', 'alt_status_purchase');
    data.append('id', id);
    data.append('status', status);
    data.append('code_send', codeSend);

    Axios({
        url : process.env.REACT_APP_API_URL + '/php/register.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('purchase', response.data.purchase);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}