import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgReturn, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_UserDash } from "services/RegisterData";

export default function Page_Users_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("access_dash"));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ pass, setPass ] = useState('');
    const [ file, setFile ] = useState(InitialData('file'));
    const [ note, setNote ] = useState(InitialData('note'));
    const [ status, setStatus ] = useState(false);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_UserDash(userId, idPage, name, email, pass, file, note, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'access_dash');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_dash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('access_dash', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("access_dash"));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPass('');
        setFile(InitialData('file'));
        setNote(InitialData('note'));
        setStatus(false);
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Users_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="16" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="60" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            {
                                idPage !=0 ?
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" value={ pass } /> :
                                <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" required />
                            }
                            <span className="name_input">Senha</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}