import { useState, useEffect } from "react";

import './Config.css';

import JoditEditor from 'jodit-react';
import { config, hour, week } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Reg_Config, Reg_IdiomaDash } from "services/RegisterData";

import { SvgDelete, SvgEdit, SvgEye, SvgEyeClose, SvgPdf, SvgSave } from "components/SvgFile";

export default function Page_Config(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('config'));

    const [ status1, setStatus1 ] = useState(true);
    const [ status2, setStatus2 ] = useState(true);

    const [ idiomaPtPt, setIdiomaPtPt ] = useState(dataPage.pt_pt);
    const [ idiomaPtBr, setIdiomaPtBr ] = useState(dataPage.pt_br);
    const [ idiomaEn, setIdiomaEn ] = useState(dataPage.en);
    const [ idiomaEs, setIdiomaEs ] = useState(dataPage.es);
    const [ idiomaDash, setIdiomaDash ] = useState(dataPage.dashboard);
    
    const [ weekStart, setWeekStart ] = useState(dataPage.week_start);
    const [ weekEnd, setWeekEnd ] = useState(dataPage.week_end);
    
    const [ hourStart, setHourStart ] = useState(dataPage.hour_start);
    const [ hourEnd, setHourEnd ] = useState(dataPage.hour_end);
                    
    const [ menuText, setMenuText ] = useState(dataPage.menu_text);
    const [ menuContact, setMenuContact ] = useState(dataPage.menu_contact);
    
    const [ contactEmail, setContactEmail ] = useState(dataPage.contact_email);
    const [ contactNumber, setContactNumber ] = useState(dataPage.contact_number);
    const [ contactAddress, setContactAddress ] = useState(dataPage.contact_address);

    const [ termsConditions, setTermsConditions ] = useState(dataPage.terms_conditions);
    const [ privacyPolicy, setPrivacyPolicy ] = useState(dataPage.privacy_policy);

    function IdiomaDashboard(value){
        props.setLoading(true);
        Reg_IdiomaDash(userId, "idioma_dashboard", value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function ShowCategory(){
        const newData = GetDataPage("products_category").filter(item => item.type == 0);
        return newData;
    }

    function IdiomaSite(type, value){
        props.setLoading(true);
        Reg_IdiomaDash(userId, type, value, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Config(userId, weekStart, weekEnd, hourStart, hourEnd, menuText, menuContact, contactEmail, contactNumber, contactAddress, termsConditions, privacyPolicy, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('config', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('config', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("config"));

        setIdiomaPtPt(dataPage.pt_pt);
        setIdiomaPtBr(dataPage.pt_br);
        setIdiomaEn(dataPage.en);
        setIdiomaEs(dataPage.es);
        setIdiomaDash(dataPage.dashboard);

        setWeekStart(dataPage.week_start);
        setWeekEnd(dataPage.week_end);
        setHourStart(dataPage.hour_start);
        setHourEnd(dataPage.hour_end);

        setMenuText(dataPage.menu_text);
        setMenuContact(dataPage.menu_contact);
        
        setContactEmail(dataPage.contact_email);
        setContactNumber(dataPage.contact_number);
        setContactAddress(dataPage.contact_address);

        setTermsConditions(dataPage.terms_conditions);
        setPrivacyPolicy(dataPage.privacy_policy);
    }, [dataPage]);

    return(
        <div className="Page_Config">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="title_page">Configuração</div>                
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>                
                </div>
                       
                {/*
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Idioma da dashboard</div>
                    </div> 
                    <div className="list_input_data idioma">
                        <div className={ idiomaDash == "pt_pt" ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaDashboard('pt_pt') } }>
                            <div className="name_status">pt_pt</div>
                            <div className="status">
                                { idiomaDash == "pt_pt" ? "ativo" : "desativado" }
                            </div>
                        </div>
                        <div className={ idiomaDash == "pt_br" ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaDashboard('pt_br') } }>
                            <div className="name_status">pt_br</div>
                            <div className="status">
                                { idiomaDash == "pt_br" ? "ativo" : "desativado" }
                            </div>
                        </div>
                        <div className={ idiomaDash == "en" ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaDashboard('en') } }>
                            <div className="name_status">en</div>
                            <div className="status">
                                { idiomaDash == "en" ? "ativo" : "desativado" }
                            </div>
                        </div>
                        <div className={ idiomaDash == "es" ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaDashboard('es') } }>
                            <div className="name_status">es</div>
                            <div className="status">
                                { idiomaDash == "es" ? "ativo" : "desativado" }
                            </div>
                        </div>
                    </div>
                </div> 
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Data e Horários</div>
                    </div> 
                    <div className="list_input_data">                        
                        <div className="div_input">
                            <select className="week" onChange={ (e)=>{ setWeekStart(e.target.value) } } value={ weekStart } required>
                                <option value="">#</option>
                                {
                                    week.map((elem, index)=>{
                                        return(
                                            <option value={ elem.value } key={ index }>{ elem.label }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Início semana</span>
                        </div>
                        <div className="div_input">
                            <select className="week" onChange={ (e)=>{ setWeekEnd(e.target.value) } } value={ weekEnd } required>
                                <option value="">#</option>
                                {
                                    week.map((elem, index)=>{
                                        return(
                                            <option value={ elem.value } key={ index }>{ elem.label }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Término semana</span>
                        </div>

                        <div className="div_input">
                            <select className="hour" onChange={ (e)=>{ setHourStart(e.target.value) } } value={ hourStart } required>
                                <option value="">#</option>
                                {
                                    hour.map((elem, index)=>{
                                        return(
                                            <option value={ elem } key={ index }>{ elem }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Horário início</span>
                        </div>
                        <div className="div_input">
                            <select className="hour" onChange={ (e)=>{ setHourEnd(e.target.value) } } value={ hourEnd } required>
                                <option value="">#</option>
                                {
                                    hour.map((elem, index)=>{
                                        return(
                                            <option value={ elem } key={ index }>{ elem }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Horário término</span>
                        </div>
                    </div>
                </div> 
                */}
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Idiomas que serão mostrados no site</div>
                    </div> 
                    <div className="list_input_data idioma">
                        <div className={ idiomaPtPt ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaSite('idioma_pt_pt', !idiomaPtPt) } }>
                            <div className="name_status">pt_pt</div>
                            <div className="status">
                                { idiomaPtPt ? "ativo" : "desativado" }
                            </div>
                        </div>
                        <div className={ idiomaPtBr ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaSite('idioma_pt_br', !idiomaPtBr) } }>
                            <div className="name_status">pt_br</div>
                            <div className="status">
                                { idiomaPtBr ? "ativo" : "desativado" }
                            </div>
                        </div>
                        <div className={ idiomaEn ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaSite('idioma_en', !idiomaEn) } }>
                            <div className="name_status">en</div>
                            <div className="status">
                                { idiomaEn ? "ativo" : "desativado" }
                            </div>
                        </div>
                        <div className={ idiomaEs ? "div_idioma idioma_active" : "div_idioma" } onClick={ ()=>{ IdiomaSite('idioma_es', !idiomaEs) } }>
                            <div className="name_status">es</div>
                            <div className="status">
                                { idiomaEs ? "ativo" : "desativado" }
                            </div>
                        </div>
                    </div>
                </div> 
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Informação do menu</div>
                    </div> 
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setMenuText(e.target.value) } } value={ menuText } maxLength="60" required />
                            <span className="name_input">Texto</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setMenuContact(e.target.value) } } value={ menuContact } maxLength="40" required />
                            <span className="name_input">Contato</span>
                        </div>
                    </div> 
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Informações de contato</div>
                    </div> 
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setContactEmail(e.target.value) } } value={ contactEmail } maxLength="60" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setContactNumber(e.target.value) } } value={ contactNumber } maxLength="40" required />
                            <span className="name_input">Contato</span>
                        </div>
                    </div> 
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setContactAddress(e.target.value) } } value={ contactAddress } required />
                            <span className="name_input">Endereço</span>
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Termos e condições</div>
                        <div className="" onClick={ ()=>{ setStatus1(!status1); } }>
                            {
                                status1 ? 
                                <SvgEye className="icons" color="#2D3C53" /> :
                                <SvgEyeClose className="icons" color="#2D3C53" />
                            }
                        </div>
                    </div> 
                    {
                        status1 ? 
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <JoditEditor config={ config } value={ termsConditions ? termsConditions.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTermsConditions(newContent) } />
                            </div>
                        </div> : null
                    }
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Politica de privacidade</div>
                        <div className="" onClick={ ()=>{ setStatus2(!status2); } }>
                            {
                                status2 ? 
                                <SvgEye className="icons" color="#2D3C53" /> :
                                <SvgEyeClose className="icons" color="#2D3C53" />
                            }
                        </div>
                    </div> 
                    {
                        status2 ? 
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                <JoditEditor config={ config } value={ privacyPolicy ? privacyPolicy.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setPrivacyPolicy(newContent) } />
                            </div>
                        </div> : null
                    }
                </div>
            </form>
        </div>
    )
}