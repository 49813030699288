import { useState, useEffect } from "react";

import './Details.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgReturn, SvgSave } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_UserSite } from "services/RegisterData";

export default function Page_UsersSite_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("access_site"));
    const [ listPurchase, setListPurchase ] = useState(GetDataPage("purchase"));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ contact, setContact ] = useState(InitialData('contact'));
    const [ household, setHousehold ] = useState(InitialData('household'));
    const [ postalCode, setPostalCode ] = useState(InitialData('postal_code'));
    const [ location, setLocation ] = useState(InitialData('location'));
    const [ district, setDistrict ] = useState(InitialData('district'));
    const [ purchase, setPurchase ] = useState(InitialData('purchase'));
    const [ password, setPassword ] = useState('');

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_UserSite(userId, idPage, name, email, contact, household, postalCode, location, district, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'access_site');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenPurchase(id){
        SetModalData('Purchase', id);
        SetModalState('Purchase', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('access_site', setDataPage);
        RegisterDataPage('purchase', setListPurchase);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('access_site', setDataPage);
            UnRegisterDataPage('purchase', setListPurchase);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("access_site"));
        setIdPage(GetListPag('currentPageId'));
        setListPurchase(GetDataPage("purchase"));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setContact(InitialData('contact'));
        setHousehold(InitialData('household'));
        setPostalCode(InitialData('postal_code'));
        setLocation(InitialData('location'));
        setDistrict(InitialData('district'));
        setPurchase(InitialData('purchase'));
        setPassword('');
    }, [dataPage, idPage]);

    useEffect(()=>{
        setPurchase(InitialData('purchase'));
    }, [listPurchase]);
    
    return(
        <div className="Page_Users_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="80" required />
                            <span className="name_input">Nome*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setContact(e.target.value) } } value={ contact } maxLength="80" required />
                            <span className="name_input">Contato*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="80" required />
                            <span className="name_input">E-mail*</span>
                        </div>
                        <div className="div_input">
                            {
                                idPage !=0 ?
                                <input type="password" className="pass" onChange={ (e)=>{ setPassword(e.target.value) } } maxLength="20" value={ password } /> :
                                <input type="password" className="pass" onChange={ (e)=>{ setPassword(e.target.value) } } maxLength="20" required />
                            }
                            <span className="name_input">Senha</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setHousehold(e.target.value) } } value={ household } maxLength="120" required />
                            <span className="name_input">Morada*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setPostalCode(e.target.value) } } value={ postalCode } maxLength="120" required />
                            <span className="name_input">Código postal*</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setLocation(e.target.value) } } value={ location } maxLength="120" required />
                            <span className="name_input">Localidade*</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setDistrict(e.target.value) } } value={ district } maxLength="120" required />
                            <span className="name_input">Distrito*</span>
                        </div>
                    </div>
                </div>
                
                {
                    idPage == 0 ? null : 
                    purchase.length > 0 ?
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Produtos comprados</div>
                        </div>
                        <table cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th width="20" align="center">#</th>
                                    <th>Data da compra</th>
                                    <th width="60">Valor</th>
                                    <th width="60">Status</th>
                                    <th width="20" align="right">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    purchase.map((elem, index)=>{
                                        let showDataPurchase = listPurchase.find(item => item.id == elem);

                                        let statusPurchase = 'Pendente';
                                        if(showDataPurchase.status == 1){
                                            statusPurchase = 'Finalizado';
                                        }else if(showDataPurchase.status == 2){
                                            statusPurchase = 'Cancelado';
                                        }
                                        return(
                                            <tr key={ index }>
                                                <td align="center">{ index + 1 }</td>
                                                <td align="left">{ showDataPurchase.date }</td>
                                                <td align="left">{ showDataPurchase.cash_total }</td>
                                                <td align="left">{ statusPurchase }</td>
                                                <td align="center">
                                                    <div className="btn" data-tooltip-content="Informações sobre a compra" data-tooltip-place="top" onClick={ ()=>{ OpenPurchase(elem) } }>
                                                        Detalhes do pedido
                                                    </div>
                                                    <Tooltip id={ "edit_" + elem } />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    : null
                }
            </form>
        </div>
    )
}