import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config, hour } from "fixedData";

import TextareaAutosize from 'react-textarea-autosize';

import { SvgReturn, SvgSave } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Health } from "services/RegisterData";

export default function Page_Health_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("health"));

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));

    const [ status, setStatus ] = useState(InitialData('status'));
    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ contact, setContact ] = useState(InitialData('contact'));
    const [ date, setDate ] = useState(InitialData('date'));
    const [ hourHealth, setHourHealth ] = useState(InitialData('hour'));
    const [ obs, setObs ] = useState(InitialData('obs'));
    const [ question, setQuestion ] = useState(InitialData('question'));
    
    const [ linkPayment, setLinkPayment ] = useState(InitialData('link_payment'));
    const [ cash, setCash ] = useState(InitialData('cash'));
    const [ statusPayment, setStatusPayment ] = useState(InitialData('status_payment'));
    const [ frequency, setFrequency ] = useState(InitialData('frequency'));
    const [ paymentMethod, setPaymentMethod ] = useState(InitialData('payment_method'));
    const [ datePayment, setDatePayment ] = useState(InitialData('date_payment'));

    const [ methodIban, setMethodIban ] = useState(InitialData('method_iban'));
    const [ methodName, setMethodName ] = useState(InitialData('method_name'));
    const [ methodEmail, setMethodEmail ] = useState(InitialData('method_email'));
    const [ methodPhone, setMethodPhone ] = useState(InitialData('method_phone'));
    const [ methodTitle, setMethodTitle ] = useState(InitialData('method_title'));
    const [ idAccount, setIdAccount ] = useState(InitialData('id_account'));
    const [ retries, setRetries ] = useState(InitialData('retries'));
    const [ areaPhone, setAreaPhone ] = useState(InitialData('area_phone'));
    const [ taxNumber, setTaxNumber ] = useState(InitialData('tax_number'));

    const [ toast, setToast ] = useState(false);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type]
        }
        if(type == 'status_payment'){
            return 0;
        }
        if(type == 'question'){
            return [];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Health(userId, idPage, status, retries, name, email, contact, date, hourHealth, obs, question, cash, statusPayment, frequency, paymentMethod, datePayment, methodIban, methodName, methodEmail, methodPhone, methodTitle, idAccount, areaPhone, taxNumber, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'health');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenGenerator(){
        SetModalData('GeneratorLink', { "type": "registrado", "idPage": idPage, "dataInitial": date, "cash": cash, "frequency": frequency, "paymentMethod": paymentMethod, "methodIban": methodIban, "methodName": methodName, "methodEmail": methodEmail, "methodPhone": methodPhone, "methodTitle": methodTitle, "idAccount": idAccount, "datePayment": datePayment, "retries": retries, "taxNumber": taxNumber, "areaPhone": areaPhone });
        SetModalState('GeneratorLink', true);
    }

    function CopyLink(){
        setToast(true);
        navigator.clipboard.writeText(linkPayment);
        setTimeout(() => {
            setToast(false);
        }, 1800);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('health', setDataPage);
        RegisterListPag('idioma', setIdioma);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('health', setDataPage);
            UnRegisterListPag('idioma', setIdioma);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("health"));
        setIdPage(GetListPag('currentPageId'));
        
        setStatus(InitialData('status'));
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setContact(InitialData('contact'));
        setDate(InitialData('date'));
        setHourHealth(InitialData('hour'));
        setObs(InitialData('obs'));
        setQuestion(InitialData('question'));
        
        setLinkPayment(InitialData('link_payment'));
        setCash(InitialData('cash'));
        setStatusPayment(InitialData('status_payment'));
        setFrequency(InitialData('frequency'));
        setPaymentMethod(InitialData('payment_method'));
        setDatePayment(InitialData('date_payment'));

        setMethodIban(InitialData('method_iban'));
        setMethodName(InitialData('method_name'));
        setMethodEmail(InitialData('method_email'));
        setMethodPhone(InitialData('method_phone'));
        setMethodTitle(InitialData('method_title'));
        setIdAccount(InitialData('id_account'));
        setAreaPhone(InitialData('area_phone'));
        setTaxNumber(InitialData('tax_number'));
        setRetries(InitialData('retries'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Health_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            <SvgReturn className="icons" color="#324d6b" />
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        {
                            idPage == 0 ? null : 
                            <div className="div_add_new_data div_text" onClick={ ()=>{ OpenGenerator() } }>
                                Gerar link de pagamento
                            </div>
                        }

                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>

                {
                    paymentMethod == "dd" ? null :
                        linkPayment == "" ? null :
                        <div className="link_payment" onClick={ ()=>{ CopyLink() } }>
                            { linkPayment }
                        </div>
                }

                {
                    toast ? 
                    <div className="div_toast">
                        Link copiado com sucesso
                    </div> : null
                }
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados da consulta</div>
                    </div> 

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <select onChange={ (e)=>{ setStatus(e.target.value) } } value={ status }>
                                <option value="0">Aguardando</option>
                                <option value="1">Finalizado</option>
                            </select>
                            <span className="name_input">Status</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" required />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setContact(e.target.value) } } value={ contact } maxLength="140" required />
                            <span className="name_input">Contato</span>
                        </div>
                        <div className="div_input">
                            <input type="date" className="date_health" onChange={ (e)=>{ setDate(e.target.value) } } value={ date } required />
                            <span className="name_input">Data da consulta</span>
                        </div>
                        <div className="div_input">
                            <select className="hour_health" onChange={ (e)=>{ setHourHealth(e.target.value) } } value={ hourHealth } required>
                                <option value="">#</option>
                                {
                                    hour.map((elem, index)=>{
                                        return(
                                            <option value={ elem } key={ index }>{ elem }</option>
                                        )
                                    })
                                }
                            </select>
                            <span className="name_input">Hora da consulta</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ obs ? obs.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObs(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do pagamento</div>
                    </div> 
                    <div className="list_input_data"> 
                        <div className="div_input">
                            <input type="date" className="date_health" onChange={ (e)=>{ setDatePayment(e.target.value) } } value={ datePayment } />
                            <span className="name_input">Data da cobrança</span>
                        </div>

                        <div className="div_input">
                            <input type="text" className="cash" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } />
                            <span className="name_input">Valor</span>
                        </div>

                        <div className="div_input space_div">
                            <select onChange={ (e)=>{ setStatusPayment(e.target.value) } } value={ statusPayment }>
                                <option value="0">Aguardando</option>
                                <option value="1">Ativado</option>
                                <option value="2">Cancelado</option>
                            </select>
                            <span className="name_input">Pagamento</span>
                        </div>
                        
                        <div className="div_input">
                            <select className="hour_health" onChange={ (e)=>{ setFrequency(e.target.value) } } value={ frequency }>
                                <option value="">#</option>
                                <option value="1D">1D</option>
                                <option value="1W">1W</option>
                                <option value="2W">2W</option>
                                <option value="1M">1M</option>
                                <option value="2M">2M</option>
                                <option value="3M">3M</option>
                                <option value="4M">4M</option>
                                <option value="6M">6M</option>
                                <option value="1Y">1Y</option>
                                <option value="2Y">2Y</option>
                                <option value="3Y">3Y</option>
                            </select>
                            <span className="name_input">Frequência</span>
                        </div>
                        <div className="div_input">
                            <select className="frequency" onChange={ (e)=>{ setPaymentMethod(e.target.value) } } value={ paymentMethod }>
                                <option value="">#</option>
                                <option value="cc">cc</option>
                                <option value="dd">dd</option>
                            </select>
                            <span className="name_input">Método de pagamento</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="retries" maxLength="4" onChange={ (e)=>{ setRetries(e.target.value) } } value={ retries } />
                            <span className="name_input">Retries</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setMethodName(e.target.value) } } value={ methodName } />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input">
                            <input type="email" className="" onChange={ (e)=>{ setMethodEmail(e.target.value) } } value={ methodEmail } />
                            <span className="name_input">E-mail</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="area_phone" onChange={ (e)=>{ setAreaPhone(e.target.value) } } value={ areaPhone } maxLength={ 6 } />
                            <span className="name_input">Área</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setMethodPhone(e.target.value) } } value={ methodPhone } />
                            <span className="name_input">Celular</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setMethodTitle(e.target.value) } } value={ methodTitle } />
                            <span className="name_input">Titular da conta</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" className="" onChange={ (e)=>{ setTaxNumber(e.target.value) } } value={ taxNumber } maxLength={ 140 } />
                            <span className="name_input">Número fiscal</span>
                        </div>
                        {
                            paymentMethod == "dd" ? 
                            <>
                                <div className="div_input">
                                    <input type="text" className="iban" onChange={ (e)=>{ setMethodIban(e.target.value) } } value={ methodIban } />
                                    <span className="name_input">Iban</span>
                                </div>
                            </> 
                            : 
                            <>
                                <div className="div_input">
                                    <input type="text" className="" onChange={ (e)=>{ setIdAccount(e.target.value) } } value={ idAccount } />
                                    <span className="name_input">id da conta</span>
                                </div>
                            </>
                        }
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Questionário respondido</div>
                    </div> 
                    {
                        question.map((elem, index)=>{
                            return(
                                <div className="list_input_data show_inf_questions" key={ index }>
                                    <div className="div_input space_div">
                                        <div className="data_text">{ elem.question }</div>
                                        <span className="name_input">Pergunta</span>
                                    </div>
                                    <div className="div_input space_div">  
                                        <div className="data_text">{ elem.response }</div>
                                        <span className="name_input">Resposta</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}